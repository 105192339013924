import React from 'react';
import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../../components/Layout';
import Banner from '../../components/Banner';
import Cards from '../../components/Cards';

const NewsInsightsPage = ({
  data: {
    datoCmsBannerImage: { newsInsightsBannerImage },
    allDatoCmsNewsInsight: { nodes },
  },
}) => (
  <Layout>
    <HelmetDatoCms title="News &amp; Insights | Barcanet" />
    <main>
      <Banner heading="News &amp; Insights" image={newsInsightsBannerImage} />
      <Cards items={nodes} baseSlug="/resources/insights/" />
    </main>
  </Layout>
);

export const NewsInsightsPageQuery = graphql`
  query NewsInsightsPageQuery {
    datoCmsBannerImage {
      newsInsightsBannerImage {
        ...BannerImageFragment
      }
    }
    allDatoCmsNewsInsight(
      sort: { fields: meta___firstPublishedAt, order: DESC }
    ) {
      nodes {
        id
        title
        slug
        featuredImage {
          fluid(
            maxHeight: 225
            maxWidth: 400
            imgixParams: { auto: "compress", fit: "crop", h: "225", w: "400" }
          ) {
            ...GatsbyDatoCmsFluid
          }
          alt
        }
        meta {
          firstPublishedAt(formatString: "DD/MM/YYYY")
        }
      }
    }
  }
`;

export default NewsInsightsPage;
